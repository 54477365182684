.appointment-time-container {
    margin-bottom: 2.5rem;

    .appointment-time-selector {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;

        .appointment-time-list {
            column-gap: 1rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            justify-items: center;
            margin: 0 1rem;
            row-gap: 1.25rem;
            touch-action: none;
            width: 100%;

            .appointment-time {
                align-items: center;
                border-radius: 0.625rem;
                cursor: pointer;
                display: flex;
                font-family: $font-family-primary;
                font-size: 1.125rem;
                height: 1.75rem;
                justify-content: center;
                line-height: 1.33;
                margin: 0;
                max-width: 3.75rem;
                width: 100%;
            }
        }
    }

    .appointment-time-see-more-label {
        color: $text-grey-45;
    }

    .appointment-time-see-more-label-disabled {
        display: none;
    }

    @media screen and (min-width: $desktop) {
        margin-bottom: 2.75rem;

        .appointment-time-selector {
            margin-bottom: 0;

            .prev-btn,
            .next-btn {
                display: block;
                margin-bottom: 0.5rem;
            }

            .appointment-time-list {
                grid-template-columns: repeat(8, 1fr);
            }
        }

        .appointment-time-see-more-label {
            display: none;
        }
    }
}
