// Split Button (Bootstrap override)
.btn-group-toggle {
    display: flex;

    .btn-primary {
        @extend %secondary-button;

        border: $button-disabled-border;
        border-radius: 0;
        color: $text-grey-65;
        flex: 0 1 50%;
        height: 1.75rem;
        width: unset;

        &:first-child {
            border-bottom-left-radius: $button-radius;
            border-top-left-radius: $button-radius;
        }

        &:last-child {
            border-bottom-right-radius: $button-radius;
            border-top-right-radius: $button-radius;
        }

        &:not(:first-child) {
            border-left: none;
        }

        &:hover {
            border: $button-border;
        }

        &.active {
            border: 2px solid $button-border-color;
            color: $secondary-button-color;
            height: 1.625rem;

            &:hover {
                border: 2px solid $button-border-color;
                color: $primary-button-color;
            }
        }

        input {
            display: none;
        }
    }
}
