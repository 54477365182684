.cancel-appointment-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cancel-appointment-date {
        font-size: 1.5rem;
        line-height: 1.17;
        margin-bottom: 0.625rem;
        text-align: center;
    }

    .cancel-appointment-text-container {
        display: flex;
        justify-content: center;

        .icon.alert-caution-red {
            margin-right: 0.75rem;
        }
    }

    .cancel-appointment-button-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .keep-appointment-button {
            order: 1;
        }

        .cancel-appointment-button {
            margin-bottom: 1.125rem;
            order: 0;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 0 3rem;

        .cancel-appointment-button-container {
            flex-direction: row;

            .keep-appointment-button {
                order: 0;
            }

            .cancel-appointment-button {
                margin: 0 0 0 1rem;
                order: 1;
            }
        }
    }
}
