.checkbox-container {
    border-radius: 1rem;
    height: min-content;
    padding: 0.313rem;
    -moz-transition: 0.25s ease-in-out;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:hover,
    &:focus {
        background-color: $hover-color;

        .default-checkbox {
            border-width: 0.125rem;
        }
    }

    .default-checkbox {
        -webkit-appearance: none;
        border: $button-border;
        border-radius: 0.25rem;
        cursor: pointer;
        display: block;
        height: 1.25rem;
        margin: 0;
        min-width: 1.25rem;
        vertical-align: middle;
        width: 1.25rem;

        &.active {
            background-color: $cadillac-dark-grey;
            content: url(../../assets/icons/checkmark.svg);
        }

        &:checked {
            &:disabled {
                background-color: $link-disabled-grey;
                content: url(../../assets/icons/checkmark.svg);
            }
        }

        &:disabled {
            border-color: $link-disabled-grey;

            &:hover {
                cursor: default;
            }
        }
    }
}
