.appointment-dealer-container {
    margin-bottom: 1.125rem;
    text-align: center;

    .appointment-dealer-name {
        font-size: 1.125rem;
    }

    .appointment-dealer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .appointment-dealer-line-2 {
            display: flex;
            justify-content: center;

            .appointment-dealer-distance {
                border-left: 1px solid $body1-font-color;
                padding: 0 0.75rem;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .appointment-dealer-info {
            flex-flow: row wrap;

            .appointment-dealer-address {
                padding-right: 0.75em;
            }

            .appointment-dealer-phone {
                border-left: 1px solid $body1-font-color;
                padding-left: 0.75rem;
            }
        }
    }
}

.dealer-information-container {
    margin-top: 1rem;
    padding: 1rem;

    .dealership-header {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .dealership-name {
        font-size: 1.125rem;
        margin: .7rem 0;
    }

    .dealership-phone {
        margin-top: 1rem;
    }
}

.dealer-information-hours-container {
    margin-top: 1rem;
    padding: 1rem;

    .dealership-hours {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .dealership-info-day {
        text-transform: capitalize;
    }

    .MuiTabs-root {
        border-bottom: $border-grey-thin;
        margin-bottom: 1.25rem;

        .MuiTab-root {
            text-transform: capitalize;
        }
    }
}

.dealer-information-amenities-container {
    margin-top: 1rem;
    padding: 1rem;

    .dealership-amenities {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}
