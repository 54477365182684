.new-contact-info-container {
    margin-bottom: 1.5rem;

    .contact-info-radio-group {
        display: flex;
        margin-bottom: 1.125rem;

        .new-contact-radio-button {
            &:first-of-type {
                margin-right: 3.375rem;
            }
        }
    }

    .contact-info-field-container {
        display: grid;
        grid-template-columns: 100%;
        row-gap: 1.25rem;

        .business-name-container,
        .customer-name-container,
        .customer-contact-info-container {
            display: grid;
            row-gap: 1.25rem;

            &:empty {
                display: none;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .contact-info-field-container {
            .business-name-container,
            .customer-name-container,
            .customer-contact-info-container {
                display: flex;
            }

            .business-name-container {
                .business-name-text-field {
                    flex: 1 1 27.75rem;
                }

                .business-dni-text-field {
                    flex: 0 0 18.125rem;
                    margin-left: 1rem;
                }

                &:empty {
                    display: none;
                }
            }

            .customer-name-container {
                .first-name-text-field,
                .last-name-text-field {
                    flex: 0 1 50%;
                }

                .first-name-text-field {
                    margin-right: 1rem;
                }
            }

            .dni-text-field {
                width: 18.125rem;
            }

            .customer-contact-info-container {
                .phone-number-text-field {
                    flex: 0 0 18.125rem;
                    margin-right: 1rem;
                }

                .email-text-field {
                    flex: 1 1 27.75rem;
                }
            }
        }
    }
}
