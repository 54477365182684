.stepper {
    display: flex;
    justify-content: flex-end;
    width: 60%;

    .stepper-container {
        flex: 1 1 7.75rem;
        max-width: 8.75rem;
        text-align: center;

        &:first-of-type {
            .stepper-contents {
                .border {
                    &:first-of-type {
                        opacity: 0;
                    }
                }
            }
        }

        &:last-of-type {
            align-items: end;

            .stepper-contents {
                .border {
                    &:last-of-type {
                        opacity: 0;
                    }
                }
            }
        }

        .stepper-contents {
            align-items: center;
            display: flex;
            flex: 0 1 2rem;

            .step {
                @extend %button, %secondary-button;

                border-radius: 50%;
                content: none;
                height: 0.875rem;
                min-width: 0.875rem;
                width: 0.875rem;

                &.past {
                    @extend %primary-button;

                    font: $font-family-primary;
                    height: 0.875rem;
                    min-width: 0.875rem;
                    width: 0.875rem;
                }
            }

            .border {
                border-top: $border-stepper-future;
                flex: 1 1 1.75rem;

                &.past {
                    border-top: $border-stepper-past;
                }
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .stepper-container {
            .stepper-contents {
                margin-bottom: 0.5rem;

                .step {
                    content: initial;
                    font: $font-family-primary;
                    font-size: 0.875rem;
                    height: 1.375rem;
                    min-width: 1.375rem;
                    width: 1.375rem;

                    &.past {
                        @extend %primary-button;

                        font: $font-family-primary;
                        height: 1.375rem;
                        min-width: 1.375rem;
                        width: 1.375rem;
                    }
                }
            }
        }
    }
}
