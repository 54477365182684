// Buttons
.btn {
    @extend %button;

    &:focus {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: none !important;
    }
}

// Primary
.btn-primary.ac-button-primary {
    @extend %primary-button;
}

// Secondary
.btn-secondary.ac-button-secondary {
    @extend %secondary-button;
}

// Primary Split
.ac-split-button-menu {
    & > a.dropdown-item:hover,
    & > a.dropdown-item:focus {
        background-color: $oss-primary-color;
    }

    & > a.dropdown-item:active,
    a.dropdown-item.active {
        background-color: $oss-primary-color;
    }
}

.dropdown-toggle.btn-primary {
    &.ac-button-primary::after {
        border-bottom: 0;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-top: 0.5rem solid;
        content: '';
    }

    &.ac-button-primary.btn-sm::after {
        border-bottom: 0;
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-top: 0.4rem solid;
        content: '';
    }

    &.ac-button-primary.btn-lg::after {
        border-bottom: 0;
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
        border-top: 0.6rem solid;
        content: '';
    }
}

[aria-expanded='true'].dropdown-toggle.dropdown-toggle-split.btn-primary {
    &.ac-button-primary {
        border: 1px solid $oss-primary-color;

        &:focus-within {
            background-color: $oss-primary-color;
        }

        &.btn-sm:focus-within {
            background-color: $oss-primary-color;
        }

        &.btn-lg:focus-within {
            background-color: $oss-primary-color;
        }
    }
}
