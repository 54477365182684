@import '../../assets/scss/main';

html {
    background-color: $white;
    font-size: 16px;


}

body {
    color: $oss-primary-color;
    font-family: $font-family-primary;
    font-size: 16px;
    margin: 0;

    // For google places dropdown in mobile service
    .pac-container {
        z-index: 10000 !important;
    }



    #root {
        margin: 0 auto 0.125rem;
        max-width: $desktop-max;

        .app-container {
            .appointment-main-container,
            .cancel-appointment-content,
            .dl-container,
            .error-page-container,
            .landing-container,
            .login-container,
            .my-garage-container,
            .pega-loading-container,
            .review-confirm-container,
            .services-container,
            .vehicle-container {
                min-height: 15rem;
            }
        }

    }
}
