@import './ConciergeForm/ConciergeForm';
@import './Contact/Contact';
@import './Dates/Day';
@import './Dates/Month';
@import './Dates/Time';
@import './Dealership/Dealership';
@import './Details/Details';
@import './Dealership/DealerShipInformation';

.appointment-main-container {
    padding: 1.875rem 1.5rem;
    padding-bottom: 10rem;

    .appointment-address-error-message{
        margin-top: 8px;
     .icon {
        height:1.25rem;
        width:1.25rem;
        display:inline-block;
        position: relative;
        top:4px;
    }
    .error-message{
        margin-left:7px;
    }

    }

    .next-btn,
    .prev-btn {
        &.disabled {
            cursor: default;
            pointer-events: none;
            -khtml-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
        }
    }

    .spinner-container {
        align-items: center;
        display: flex;
        min-height: 12.125rem;
    }

    .appointment-headline-border {
        margin-bottom: 1.875rem;

        &.apt-headline2 {
            margin-bottom: 0.625rem;
        }
    }

    .appointment-first-available {
        margin-bottom: 1rem;
        display: flex;
        flex-wrap: wrap;
    }
    .select-first-available {
        margin-left: 0.5rem;
    }
    .call-dealership {
        display: contents;

        @media screen and (min-width: $desktop) {
            display: none;
        }
    }
    @media screen and (min-width: $desktop) {
        padding: 3.125rem 6.5rem;
        padding-bottom: 10rem;

        .appointment-first-available {
            display: flex;
            margin-bottom: 2.25rem;

            .appointment-first-avail {
                margin-right: 0.3em;
            }
        }

        .appointment-headline-border {
            margin-bottom: 1.875rem;

            &.apt-headline2 {
                margin-bottom: 0.625rem;
            }
        }
    }
}
