@import '../MoreInfo/MoreInfo';
@import '../NeedSomethingElse/NeedSomethingElse';
@import './OdometerPopup';

.services-container {
    padding: 1.875rem 1.5rem;
    padding-bottom: 6rem;
    position: relative;

    .change-dealer-message {
        color: $error-red;
        display: flex;
        justify-content: space-between;
    }

    .change-dealer-link {
        margin-left: 5px;
    }

    .headline-border {
        margin-bottom: 0.625rem;
    }

    .services-subtitle {
        margin-bottom: 0.5rem;
        padding-left: 0;

        .mileage-value {
            margin-left: 0.2em;
        }
    }

    .deployment-mode {
        bottom: 0.25rem;
        position: absolute;
        right: 1rem;
    }

    .service-table {
        margin-bottom: 1.125rem;

        .service-item {
            padding: 1.5rem 1rem;

            .recall {
                &-header {
                    align-items: center;
                    display: flex;

                    &-label {
                        @extend %bold, %caps;

                        color: $error-red;
                    }

                    &-icon {
                        height: $svg-medium;
                        margin-right: 0.5rem;
                        width: $svg-medium;
                    }
                }
            }

            .title-container {
                width: 100%;
            }

            .service-item-row {
                display: flex;
                width: 100%;

                > div {
                    &:not(:last-child) {
                        margin-right: 1.25rem;

                        &:empty {
                            display: none;
                        }
                    }
                }

                &:empty {
                    display: none;
                }

                &:not(:last-of-type) {
                    margin-bottom: 0.5rem;
                }

                .title-container {
                    width: 100%;
                }

                .display-price {
                    margin-right: 0.5rem;
                }

                .recommended-subtitle {
                    align-items: center;
                    display: flex;

                    .recommended-icon {
                        height: 0.875rem;
                        margin-right: 0.25rem;
                        width: 0.875rem;
                    }
                }

                .desc-title {
                    width: 100%;
                }
            }
        }
    }

    .tire-finder-table {
        .tire-finder-item {
            justify-content: unset;
        }
    }

    .MuiAccordion-root {
        border-bottom: $border-table;
        color: $body1-font-color;

        &.Mui-expanded {
            margin: 0;
        }

        &::before,
        &.Mui-disabled {
            background-color: transparent;
        }

        .MuiCollapse-container {
            background-color: $light-grey-f5;
            border-top: $border-table;

            .MuiAccordionDetails-root {
                padding: 2rem;
            }
        }

        .service-item-content {
            min-height: unset;
            padding: 0;

            &.Mui-Focused {
                background-color: $hover-color;
            }

            .MuiAccordionSummary-content {
                flex-direction: column;
                margin: 0;

                &.Mui-expanded {
                    margin: 0;
                }
            }
        }
    }

    .privacy-statement-for-service {
        margin-top: 1.25rem;
    }

    @media screen and (min-width: $desktop) {
        padding: 3.125rem 6.375rem;
        padding-bottom: 6rem;

        .service-table {
            .service-item {
                .service-item-row {
                    > div {
                        &:not(:last-child) {
                            margin-right: 2.25rem;
                        }
                    }
                }
            }
        }
    }
}

// Offer pricing should have the same styling
.display-price {
    align-items: flex-start;
    display: flex;

    > div {
        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    .display-price-tagline {
        margin-right: 2rem;

        &:empty {
            display: none;
        }
    }
}

.display-price-currency {
    display: flex;

    .display-price-unit,
    .display-price-before-decimal,
    .display-price-after-decimal {
        display: inline;
        vertical-align: top;
    }

    .display-price-before-decimal {
        font-size: 2.125rem;
        line-height: 1;
    }

    .display-price-unit {
        &.reverted-currency-symbol {
            order: 1;
        }
    }
}
