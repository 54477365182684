@import './Customer';

.appointment-contact-info-container {
    margin-top: 3.125rem;

    .appointment-headline-border {
        &.headline-border {
            margin-bottom: 1.625rem;
        }
    }

    .contact-opt-in-container,
    .contact-disclaimer-container {
        &:not(:first-child) {
            @extend %disclaimer;
        }

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }

        .contact-terms-conditions-link {
            margin-left: 0.3em;
        }
    }

    .contact-opt-in-container {
        display: flex;

        .alert-caution-grey {
            height: 1.375rem;
            margin: 0.375rem 0.375rem 0 0;
            min-width: $svg-medium;
        }

        .checkbox-container {
            margin: 0 0.625rem 0 0;
        }

        .contact-opt-in-label {
            align-self: center;
            color: $text-grey-65;
        }

        .error-message {
            margin-top: 0.5rem;
        }
    }

    .contact-info-hidden-edit-link {
        font-size: 1rem;
    }

    @media screen and (min-width: $desktop) {
        .contact-opt-in-container,
        .contact-disclaimer-container {
            &:not(:last-child) {
                margin-bottom: 1.75rem;
            }
        }
    }
}
