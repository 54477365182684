.dealer-locator-indicator{
  .mobile-service-icon{
    height: 1.25rem;
    width:1.25rem;
    display:inline-block;
    position: relative;
    top:4px;
  }
  span{
    margin-left: 4px;
  }
}

.mobile-service-not-available {
    .icon {
      height:4.25rem;
      width:4.25rem;
    }
}

.mobile-service-not-available-divider-padding{
  padding-top: 20px;
}

.mobile-service-not-available-divider {
  border-bottom: 1px solid gray;
}