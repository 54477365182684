$icon-path: '../icons';

$element-map: (
    alert-caution-grey: url('#{$icon-path}/alert-caution-grey.svg'),
    alert-caution-red: url('#{$icon-path}/alert-caution-red.svg'),
    alert-caution-yellow: url('#{$icon-path}/alert-caution-yellow.svg'),
    arrow-down-buick: url('#{$icon-path}/arrow-down-buick.svg'),
    arrow-down-cadillac: url('#{$icon-path}/arrow-down-cadillac.svg'),
    arrow-down-chevy: url('#{$icon-path}/arrow-down-chevy.svg'),
    arrow-down-gm: url('#{$icon-path}/arrow-down-gm.svg'),
    arrow-down-gmc: url('#{$icon-path}/arrow-down-gmc.svg'),
    arrow-left-unavailable: url('#{$icon-path}/arrow-left-unavailable.svg'),
    arrow-right: url('#{$icon-path}/arrow-right.svg'),
    checkmark: url('#{$icon-path}/checkmark.svg'),
    checkmark-grey: url('#{$icon-path}/checkmark-grey.svg'),
    close: url('#{$icon-path}/close.svg'),
    error: url('#{$icon-path}/error.svg'),
    info: url('#{$icon-path}/info.svg'),
    location-airplane: url('#{$icon-path}/location-airplane.svg'),
    search: url('#{$icon-path}/search.svg'),
    star-unselected: url('#{$icon-path}/star-unselected.svg'),
    star: url('#{$icon-path}/star.svg'),
    battery: url('#{$icon-path}/battery.svg'),
);

.icon {
    &.alert-caution-grey {
        @include insert-icon(alert-caution-grey, $svg-medium, $svg-medium);
    }

    &.alert-caution-red {
        @include insert-icon(alert-caution-red, $svg-large, 2.125rem);
    }

    &.alert-caution-yellow {
        @include insert-icon(alert-caution-yellow, 1.125rem, $svg-medium);

        min-width: 1.25rem;
    }

    &.arrow-left {
        @include insert-icon(arrow-right, $svg-medium, $svg-small);

        cursor: pointer;
        transform: rotate(180deg);
    }

    &.arrow-left-disabled {
        @include insert-icon(arrow-left-unavailable, $svg-medium, $svg-small);
    }

    &.arrow-right {
        @include insert-icon(arrow-right, $svg-medium, $svg-small);

        cursor: pointer;
    }

    &.arrow-right-disabled {
        @include insert-icon(arrow-left-unavailable, $svg-medium, $svg-small);

        transform: rotate(180deg);
    }

    &.checkmark {
        @include insert-icon(checkmark, $svg-small, $svg-small);
    }

    &.checkmark-grey {
        @include insert-icon(checkmark-grey, $svg-small, $svg-small);
    }

    &.close {
        @include insert-icon(close, $svg-medium, $svg-medium);

        cursor: pointer;
    }

    &.error {
        @include insert-icon(error, $svg-medium, $svg-medium);
    }

    &.info {
        @include insert-icon(info, $svg-large, $svg-large);
    }

    &.location-airplane {
        @include insert-icon(location-airplane, 1.5rem, 1.5rem);
    }

    &.search {
        @include insert-icon(search, $svg-medium, $svg-medium);
    }

    &.star-unselected {
        @include insert-icon(star-unselected, $svg-small, $svg-small);
    }

    &.star {
        @include insert-icon(star, $svg-small, $svg-small);
    }

    &.battery {
        @include insert-icon(battery, $svg-small, $svg-small);
    }
}
