// Styles for review/confirm pages
.review-confirm-container {
    padding: 1.875rem 1.5rem;
    padding-bottom: 6rem;

    .paper {
        border-radius: 0;
        margin-bottom: 0.625rem;
        padding: 1.125rem 1rem 1.125rem 1.25rem;
    }

    .review-confirm-appointment-date-time-section {
        .review-confirm-appointment-date-time {
            font-size: 1.5rem;
            text-align: center;
        }

        .MuiCircularProgress-root {
            position: relative;
            top: 2.5rem;
        }

        .submit-review {
            display: block;
            margin: 1rem;
            text-align: center;
            .submit-appointment-button {
                display: flex;
                justify-content: center;
                margin: 1rem;
            }
        }
    }

    .review-confirm-appointment-details {
        .review-confirm-appointment-advisor {
            margin-top: 1rem;
        }
    }

    .review-confirm-appointment-details-transport-option-selected {
        margin-top: 1rem;
    }

    .review-service-details-container {
        margin-top: 1rem;
    }

    .review-confirm-appointment-details-dealer-info {
        margin-top: 1rem;
    }

    .review-confirm-instructions-dropoff {
        margin-top: 1rem;
    }

    .review-confirm-instructions-phone {
        margin-top: 1rem;
    }

    .review-confirm-special-instructions {
        margin-top: 1rem;
    }

    .review-confirm-appointment-details-title-container,
    .review-confirm-comments-title,
    .review-confirm-customer-title-container,
    .review-confirm-services-title-container,
    .review-confirm-vehicle-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.625rem;
    }

    .review-confirm-services-list {
        .service-item {
            color: $body1-font-color;
            padding-bottom: 0.75rem;

            &:not(:nth-last-of-type(-n + 2)) {
                border-bottom: $border-table;
                margin-bottom: 0.75rem;
                padding-bottom: 0.75rem;
            }

            &.review-confirm-services-comments {
                &:not(:empty) {
                    border-top: $border-table;
                    margin-top: 0.75rem;
                    overflow-wrap: anywhere;
                    padding-top: 0.75rem;
                }
            }

            .service-offer,
            .service-price {
                margin-left: 1rem;
            }

            .service-title {
                @extend %bold;

                color: $body1-font-color;
            }

            .service-offer {
                font-size: $body2-font-size;
            }

            .service-price {
                display: flex;
                margin-top: 0.5rem;

                &-value:not(:empty) {
                    margin-right: 0.3em;
                }
            }
        }
    }

    .review-confirm-services-disclaimer {
        color: $text-grey-65;
        display: flex;
        margin-bottom: 0.625rem;

        &:empty {
            display: none;
        }

        .review-confirm-services-disclaimer-container {
            display: flex;

            .review-confirm-services-disclaimer-icon {
                margin: 0.25rem 0.25rem 0 0;
            }
        }
    }

    .review-confirm-title {
        padding: 0rem;
    }

    .review-confirm-subtitle {
        padding-bottom: 1rem;
    }

    .review-confirm-headline-border {
        margin-bottom: 1.875rem;
    }

    .review-confirm-vehicle-info {
        display: flex;
        flex-direction: column;

        .review-confirm-vehicle-text {
            margin-bottom: 0.625rem;
        }

        .review-confirm-vehicle-image {
            height: auto;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    .review-confirm-grid {
        .review-confirm-services-title-container,
        .review-confirm-appointment-details-title-container,
        .review-confirm-customer-title-container,
        .review-confirm-comments-title {
            margin-bottom: 1.25rem;

            .headline3 {
                margin-right: 1rem;
            }
        }

        .submit-appointment-disclaimer {
            display: flex;
            flex-direction: column;

            .submit-appointment-disclaimer-title {
                margin-bottom: 0.625rem;
            }
        }

        .review-confirm-comments,
        .submit-appointment-disclaimer {
            .submit-appointment-disclaimer-statement,
            .review-comments {
                border-radius: 0.625rem;
                max-width: 45rem;
                overflow-wrap: break-word;
                padding: 1rem;
                white-space: pre-wrap;
            }
        }

        .review-comments-box {
            margin-bottom: 0.625rem;
        }

        .feed-back-box {
            background-color: $light-grey-f5;

            .feed-back-submitted {
                padding: 2rem;
                text-align: center;
            }

            .star-rating {
                padding-bottom: 1rem;
                text-align: center;

                .star-button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    font-size: xx-large;
                    @supports (-webkit-touch-callout: none) {
                        padding: 10px;
                        }
                }

                .on {
                    color: $chevy-gold;
                }

                .off {
                    color: $fill-line-grey;
                }
            }

            .feed-back-options {
                justify-content: center;
                padding-bottom: 1rem;

                .feed-back-selection {
                    flex-direction: column;
                    font-size: small;

                    .feed-back-option {
                        border: 2px solid transparent;
                        cursor: pointer;
                        display: flex;
                        margin: 0.25rem;
                        padding: 0.5rem;
                        width: fit-content;

                        &:hover {
                            background-color: $btn-hover-grey;
                            border-color: $btn-hover-grey;
                            color: $white;
                        }

                        &.MuiPaper-outlined {
                            background-color: $btn-hover-grey;
                            border-color: $chevy-gold;
                            color: $white;
                        }
                    }
                }
            }

            .feed-back-submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    .review-confirm-appointment-details-columns {
        display: flex;
        justify-content: center;
        margin-top: 0.625rem;

        .review-confirm-appointment-details-map-button {
            margin-right: 1rem;
        }
    }

    .review-confirm-customer-columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .review-confirm-my-rewards {
            color: $text-grey-65;
            font-weight: bold;
            margin-top: 0.5rem;
        }

        .review-confirm-points {
            color: $text-grey-65;
            line-height: 1.38;
        }

        .review-confirm-customer-info-hidden {
            font-style: italic;
        }
    }

    .review-confirm-services-edit,
    .review-confirm-customer-edit {
        text-align: center;
    }

    .review-confirm-appointment-date-time-cancel {
        display: flex;
        font-size: 1rem;
        justify-content: center;
        padding-top: 1rem;

        .alert-caution-red {
            margin-right: 0.5rem;
        }
    }

    .review-confirm-appointment-edit-cancel-link-container {
        margin-top: 1rem;
        text-align: center;

        .review-confirm-appointment-edit-cancel-link {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .confirmation-appointment-link {
            font-size: 1rem;
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 3.125rem 6.5rem;
        padding-bottom: 6rem;

        .review-header-container {
            display: flex;
            .review-title-container {
                display: block;
            }

            .submit-review {
                margin-left: auto;
                margin-right: 0;
                margin-top: 0;
            }
        }

        .review-confirm-headline-border {
            margin-bottom: 2.5rem;
        }

        .review-confirm-vehicle-info {
            flex-direction: row;
            justify-content: space-between;

            .review-confirm-vehicle-image {
                height: 100%;
                margin: 0;
            }
        }

        .review-confirm-grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto auto;
        }

        .review-confirm-appointment-details-columns {
            justify-content: start;
        }

        .review-confirm-services-edit,
        .review-confirm-customer-edit {
            text-align: left;
        }

        .review-confirm-appointment-dealer-disclaimer {
            margin-left: 0.5rem;
        }
    }

    .review-confirm-services-info {
        &.paper {
            padding: 1.125rem 0 0;
        }

        .review-confirm-services-content {
            padding: 0 1rem 0 1.25rem;
        }

        .check-box-wrapper {
            align-items: center;
            display: flex;
            padding: 1.125rem 0;

            &.silver {
                border-left: 4px solid $fill-tier-silver;
            }

            &.gold {
                border-left: 4px solid $fill-tier-gold;
            }

            &.platinum {
                border-left: 4px solid $fill-tier-platinum;
            }

            &.gray {
                background-color: $light-grey-f5;
            }

            .checkbox-container {
                margin-left: 0.625rem;

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }

            .check-box-text {
                margin-left: 1rem;
            }
        }
    }
}


