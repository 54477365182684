.table,
table {
    width: 100%;

    th,
    tr {
        align-items: flex-start;
        border-bottom: $border-table;
        display: flex;
        justify-content: space-between;
        padding: 1.125rem 0;
    }
}
