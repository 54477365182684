.odometer-form {
    height: calc(100% - 1.5rem);
    position: relative;

    .odometer-headline {
        margin-bottom: 1.5rem;
    }

    .mileage-input {
        &.MuiFormControl-root {
            .MuiFormLabel-root {
                @extend %caps;
            }
        }

        .alert-caution-red {
            height: 1.125rem;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 0 3rem;
    }
}

.service-list-two {
    .MuiAccordionSummary-content{
        display:block !important;
        margin:0;
    }
}

