// Colors
$border-grey: #e2e2e3;
$btn-disabled-background-grey: #959495;
$btn-disabled-grey: #d7d7d7;
$btn-disabled-white: rgba(255, 255, 255, 0.75);
$btn-hover-grey: #5d6166;
$buick-orange: #f26522;
$cadillac-dark-grey: #343a40;
$cadillac-gold: #bead76;
$chevy-gold: #b6862d;
$dark-grey: #333;
$error-red: #b82d33;
$gm-blue: #134a9c;
$gmc-red: #c00;
$light-grey-ac: #acacac;
$light-grey-dbd: #dbdddf;
$light-grey-dc: #dcdee0;
$light-grey-e9: #e9e9e9;
$light-grey-f5: #f5f5f5;
$link-disabled-grey: #d6d8d9;
$text-grey-45: #454545;
$text-grey-65: #656565;
$white: #fff;
$brand-color: $gm-blue;
$hover-color: $link-disabled-grey;
$fill-tier-silver: #d9d9d6;
$fill-tier-gold: #decd86;
$fill-tier-platinum: #5b5c5b;
$fill-line-grey: #999c9f;

// Viewports
$desktop: 768px;
$desktop-max: 960px;
$mobile: 385px;

// Default Colors
$oss-primary-color: $cadillac-dark-grey;
$oss-invert-color: $white;
$form-fill: $light-grey-f5;

// Fonts
// Default fonts
$font-family-primary: GMGlobalSans, sans-serif;
$font-family-bold: GMGlobalSansBold, sans-serif;
$font-family-Louis2: LouisGlobal2, sans-serif;
$font-family-Louis2Reg: LouisGlobal2Reg, sans-serif;
$font-family-CadillacSansA: CadillacSansA, sans-serif;
$font-family-StratumGMC: StratumGMC, sans-serif;

// Base font size
$base-font-size: 16px;

// Links
$link-color: $cadillac-dark-grey;
$link-decoration: underline;
$link-disabled-color: $btn-disabled-grey;
$link-font-family: $font-family-bold;
$link-hover-color: $btn-hover-grey;
$link-line-height: 1.38;

// Headline Font Sizes
$default-headline-color: $cadillac-dark-grey;

// Headline1
$headline1-color: $default-headline-color;
$headline1-font-family: $font-family-bold;
$headline1-font-size-desktop: 1.5rem;
$headline1-font-size: 1.5rem;
$headline1-line-height-desktop: 1.17;
$headline1-line-height: 1.17;

// Headline2
$headline2-color: $default-headline-color;
$headline2-font-family: $font-family-bold;
$headline2-font-size-desktop: 1.5rem;
$headline2-font-size: 1.5rem;
$headline2-line-height-desktop: 1.17;
$headline2-line-height: 1.2;

// Headline3
$headline3-color: $default-headline-color;
$headline3-font-family: $font-family-bold;
$headline3-font-size-desktop: 1.375rem;
$headline3-font-size: 1.375rem;
$headline3-line-height-desktop: 1.36;
$headline3-line-height: 1.36;

// Label
$label-color: $cadillac-dark-grey;
$label-font-size: 1.125rem;
$label-line-height: 1.33;

// Body
$body-font-family: $font-family-primary;

// Body1
$body1-font-color: $cadillac-dark-grey;
$body1-font-size: 1rem;
$body1-line-height: 1.38;

// Body2
$body2-font-color: $cadillac-dark-grey;
$body2-font-size: 0.875rem;
$body2-line-height: 1.43;

// Descriptive
$desc-font-color: $text-grey-65;
$desc-font-size: $body2-font-size;
$desc-line-height: 1.57;

// Disclaimer
$disclaimer-font-color: $text-grey-65;
$disclaimer-font-size: $body2-font-size;
$disclaimer-line-height: 1.43;

// Borders
$border-brand-thick: 8px solid $brand-color;
$border-brand-thick_neutral: 8px solid $white;
$border-brand-thin: 2px solid $brand-color;
$border-card: 1px solid $light-grey-e9;
$border-grey-thin: 1px solid $border-grey;
$border-primary-thin: 1px solid $oss-primary-color;
$border-table: 1px solid $light-grey-dbd;
$border-stepper-future: 1px dashed $link-disabled-grey;
$border-stepper-past: $border-primary-thin;

// Buttons
$button-border-color: $cadillac-dark-grey;
$button-border: 1px solid $button-border-color;
$button-disabled-color: $oss-invert-color;
$button-disabled-background-color: $btn-disabled-grey;
$button-disabled-border: 1px solid $btn-disabled-grey;
$button-font-family: $font-family-bold;
$button-font-size: $body2-font-size;
$button-font-size-desktop: $body1-font-size;
$button-height-desktop: 3.125rem;
$button-height: 2.5rem;
$button-line-height: $body2-line-height;
$button-line-height-desktop: $body1-line-height;
$button-radius-desktop: 1.563rem;
$button-radius: 1.25rem;
$button-width-desktop: 18.125rem;
$button-width: 16.875rem;

// Primary Button
$primary-button-background-color: $cadillac-dark-grey;
$primary-button-disabled-background-color: $btn-disabled-background-grey;
$primary-button-disabled-color: $btn-disabled-white;
$primary-button-color: $oss-invert-color;
$primary-button-hover-background-color: $btn-hover-grey;
$primary-button-hover-border: 1px solid $btn-hover-grey;

// Secondary Button
$secondary-button-background-color: $white;
$secondary-button-color: $cadillac-dark-grey;

// Radio button
$radio-button-border: 1px solid $light-grey-ac;
$radio-button-background: transparent;
$radio-button-dot: $oss-primary-color;

// Icons
$svg-small: 1rem;
$svg-medium: 1.25rem;
$svg-large: 1.875rem;

$brand-arrow: arrow-down-gm;
