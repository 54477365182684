@mixin font-values($font-color, $font-size, $line-height) {
    color: $font-color;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin headline-values(
    $color,
    $font-size,
    $line-height,
    $headline-font-family,
    $font-size-desktop,
    $line-height-desktop
) {
    @include font-values($color, $font-size, $line-height);

    font-family: $headline-font-family;
    margin: 0;
    padding: 0;

    &.invert {
        color: $oss-invert-color;
    }

    @media screen and (min-width: $desktop) {
        @include font-values($color, $font-size-desktop, $line-height-desktop);
    }
}

@function get-element($key) {
    @return map-get($element-map, $key);
}

@mixin insert-icon($background-image, $height: null, $width: null, $background-hover-image: null) {
    background: get-element($background-image) no-repeat;
    background-size: contain;
    box-sizing: border-box;
    display: block;
    height: $height;
    width: $width;

    @if $background-hover-image {
        &:hover {
            background: get-element($background-hover-image) no-repeat;
        }
    }
}
