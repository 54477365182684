.error-page-container {
    padding: 5rem 2rem;

    .error-message-header {
        align-items: center;
        display: flex;
        margin-bottom: 2rem;

        .error-icon {
            margin-right: 1rem;
        }

        .error-message-sorry {
            color: $error-red;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 5rem 3rem;
    }
}
