.modal {
    background-color: $white;
    display: none;
    height: 100%;
    min-height: 15rem;
    outline: 0;
    -ms-overflow-style: none;
    overflow-y: scroll;
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: 0 !important;
    position: fixed;
    scrollbar-width: none;
    top: 0;
    width: 100%;
    z-index: 50;

    &::-webkit-scrollbar {
        display: none;
    }

    .modal-dialog {
        margin: 0 auto;
        max-width: $desktop-max;
        pointer-events: none;
        position: relative;

        .modal-content {
            padding: 1.875rem 1.625rem;
            pointer-events: all;
            transition: transform 0.3s ease-out;

            .ac-header {
                color: $default-headline-color;
                font-family: $font-family-bold;
            }

            .modal-header {
                margin-bottom: 1.875rem;

                .header-close {
                    @extend %bold;

                    align-items: center;
                    cursor: pointer;
                    display: flex;

                    .header-close-icon {
                        margin-right: 0.625rem;
                    }
                }
            }

            .modal-footer {
                border-top: 0;
            }

            .primary-button {
                margin: 2rem auto 0;
                width: 100%;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .modal-dialog {
            .modal-content {
                padding: 1.875rem;
            }
        }
    }
}

.edit-button {
    margin-top:16px;
}