.offer-container {
    .offer-description,
    .offer-disclaimer,
    .offer-label,
    .offer-subtitle,
    .offer-title {
        @extend %body2;

        margin-bottom: 0.625rem;
    }

    .offer-headline {
        margin-bottom: 1.5rem;
    }

    .offer-table {
        .offer-item {
            margin-bottom: 0.625rem;
            padding: 1rem;

            .offer-disclaimer,
            .offer-description {
                margin-left: 1.875rem;
            }

            .radio-button-label {
                @extend %bold;

                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .offer-disclaimer {
                font-style: italic;
            }

            .display-price,
            .offer-description,
            .offer-disclaimer,
            .offer-price,
            .offer-subtitle {
                margin-left: 2rem;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .offer-table {
            .offer-item {
                .radio-button-label {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}
