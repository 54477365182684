// Text
%bold {
    font-family: $font-family-bold;
}

%headline1 {
    @include headline-values(
        $default-headline-color,
        $headline1-font-size,
        $headline1-line-height,
        $headline1-font-family,
        $headline1-font-size-desktop,
        $headline1-line-height-desktop
    );
}

%headline2 {
    @include headline-values(
        $default-headline-color,
        $headline2-font-size,
        $headline2-line-height,
        $headline2-font-family,
        $headline2-font-size-desktop,
        $headline2-line-height-desktop
    );

    padding-bottom: 1rem;
}

%headline3 {
    @include headline-values(
        $default-headline-color,
        $headline3-font-size,
        $headline3-line-height,
        $headline3-font-family,
        $headline3-font-size-desktop,
        $headline3-line-height-desktop
    );
}

%body {
    color: $body1-font-color;
    font-family: $body-font-family;

    &.bold {
        font-family: $font-family-bold;
    }
}

%body1 {
    @extend %body;
    @include font-values($body1-font-color, $body1-font-size, $body1-line-height);
}

%body2 {
    @extend %body;
    @include font-values($body2-font-color, $body2-font-size, $body2-line-height);
}

%desc {
    @extend %body;
    @include font-values($desc-font-color, $desc-font-size, $desc-line-height);
}

%label {
    color: $label-color;
    font-family: $font-family-bold;
    font-size: $label-font-size;
    line-height: $label-line-height;
}

%disclaimer {
    color: $disclaimer-font-color;
    font-size: $disclaimer-font-size;
    line-height: $disclaimer-line-height;
}

%link {
    color: $link-color;
    cursor: pointer;
    font-family: $link-font-family;
    line-height: $link-line-height;
    text-decoration: $link-decoration;
    -moz-transition: 0.25s ease-in-out;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:hover {
        color: $link-hover-color;
    }

    &:disabled,
    &.disabled,
    &.disabled-link {
        color: $link-disabled-color;
    }

    &:not(.button) {
        /* stylelint-disable-next-line declaration-no-important */
        background: none !important;
        border: none;
        /* stylelint-disable-next-line declaration-no-important */
        padding: 0 !important;
    }
}

%error-message {
    color: $error-red;
    font-size: 0.875rem;
    line-height: 1.43;
}

%invert {
    color: $oss-invert-color;
}

%caps {
    text-transform: uppercase;
}

// Remove outlines on active/focus
// Note: This reduces accessibility but it was confirmed that I should remove.
:active,
:focus {
    outline: none;
}

// Button components
%button {
    align-items: center;
    border-radius: $button-radius;
    cursor: pointer;
    display: flex;
    font-family: $button-font-family;
    font-size: $button-font-size;
    height: $button-height;
    justify-content: center;
    line-height: $button-line-height;
    max-width: $button-width;
    text-decoration: none;
    -moz-transition: 0.25s ease-in-out;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    width: $button-width;

    &:not(.icon) {
        border: $button-border;
    }

    &:disabled,
    &.disabled,
    &.disabled-link {
        cursor: default;
        pointer-events: none;
        -khtml-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    @media screen and (min-width: $desktop) {
        border-radius: $button-radius-desktop;
        font-size: $button-font-size-desktop;
        height: $button-height-desktop;
        line-height: $button-line-height-desktop;
        width: $button-width-desktop;
    }
}

%primary-button {
    background-color: $primary-button-background-color;
    color: $primary-button-color;

    &:hover {
        background-color: $primary-button-hover-background-color;
        border: $primary-button-hover-border;
        color: $primary-button-color;
    }

    &:disabled,
    &.disabled,
    &.disabled-link {
        background-color: $primary-button-disabled-background-color;
        border: 1px solid $btn-disabled-background-grey;
        color: $primary-button-disabled-color;
    }
}

%secondary-button {
    background-color: $secondary-button-background-color;
    color: $secondary-button-color;

    &:hover {
        background-color: $primary-button-background-color;
        color: $primary-button-color;
    }

    &:disabled,
    &.disabled,
    &.disabled-link {
        opacity: 0.2;
    }
}

%radio-button {
    .default-radio-button {
        cursor: pointer;
        height: 1.25rem;
        opacity: 0;
        position: relative;
        top: 3px;
        width: 1.25rem;
        z-index: 1;
    }

    .radio-button-label {
        display: inline-block;
        position: relative;

        &::before {
            background: $radio-button-background;
            border: $radio-button-border;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 1.125rem;
            left: -30px;
            position: absolute;
            width: 1.125rem;
        }

        &::after {
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 0.875rem;
            left: -27px;
            position: absolute;
            top: 3px;
            width: 0.875rem;
        }
    }

    &.checked {
        .radio-button-label {
            &::after {
                background: $radio-button-dot;
            }
        }
    }
}

// Misc

%headline-border {
    border-bottom: $border-brand-thin;
    width: 4.688rem;

    @media screen and (min-width: $desktop) {
        width: 7.5rem;
    }
}

%text-area {
    @extend %body1;

    border: $border-primary-thin;
    border-radius: 0.625rem;
    padding: 1rem;
    resize: none;
    width: calc(100% - 2rem);

    &::placeholder {
        color: $text-grey-65;
    }
}

%paper {
    background-color: $white;
    border: $border-card;
    border-radius: 0.625rem;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.05);
}

// Add to these elements
.link,
a {
    @extend %link;
}

.invert {
    @extend %invert;
}

.headline1,
h1 {
    @extend %headline1;
}

.headline2,
h2 {
    @extend %headline2;
}

.headline3,
h3 {
    @extend %headline3;
}

b,
.bold {
    @extend %bold;
}

.body1 {
    @extend %body1;
}

.body2 {
    @extend %body2;
}

.desc {
    @extend %desc;
}

.disclaimer {
    @extend %disclaimer;
}

.label {
    @extend %label;
}

.button,
.btn {
    @extend %button;
}

.primary-button,
.btn-primary,
.ac-button-primary {
    @extend %button, %primary-button;
}

.secondary-button,
.btn-secondary,
.ac-button-secondary {
    @extend %button, %secondary-button;
}

.error-message {
    @extend %body1, %error-message;
}

.headline-border {
    @extend %headline-border;
}

.text-area,
textarea {
    @extend %text-area;
}

::placeholder {
    color: $text-grey-65;
}

.caps {
    @extend %caps;
}

.paper {
    @extend %paper;
}

.radio-button {
    @extend %radio-button;
}

.hide {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
}

.invisible {
    visibility: hidden;
}

.hide-on-mobile {
    display: none;

    @media screen and (min-width: $desktop) {
        display: block;
    }
}

.hide-on-desktop {
    display: block;

    @media screen and (min-width: $desktop) {
        display: none;
    }
}
