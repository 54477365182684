.login-container {
    justify-content: center;
    padding: 1.875rem 1.5rem 5rem;

    .forgot-password-text {
        margin: 1rem 0;
        text-align: center;
    }

    .guest-button {
        margin: 0 auto;
        padding-bottom: 0;
    }

    .login-form {
        margin: 1rem auto;

        .login-submit {
            margin: 0 auto;
        }

        .login-error {
            border-left: 3px solid $error-red;

            .icon {
                height: $svg-medium;
                width: $svg-medium;
            }
        }

        .MuiGrid-item {
            max-width: 30rem;
        }
    }

    @media screen and (min-width: $desktop) {
        .landing-prompt {
            font-family: $font-family-bold;
            font-size: $headline3-font-size-desktop;
            line-height: $headline3-line-height-desktop;
            text-align: center;
        }
    }
}
