.dl-container {
    padding: 1.875rem 1.5rem;
    padding-bottom: 6rem;

    .secondary-button {
        width: 100%;
    }

    .dl-results-label {
        margin: 0.625rem 0 1.125rem;
    }

    .dl-search-input {
        margin-bottom: 1.125rem;
    }

    .MuiCircularProgress-root {
        /* stylelint-disable-next-line declaration-no-important */
        height: 1.5rem !important;
        margin: 1rem auto;
        /* stylelint-disable-next-line declaration-no-important */
        width: 1.5rem !important;
    }

    .locator-radio-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;

        .locator-radio-button {
            margin-right: 1rem;
        }
    }

    .main-row {
        display: flex;
        flex-direction: column;

        .list-container {
            .button-container {
                align-items: center;
                cursor: pointer;
                display: flex;

                .btn-group-toggle {
                    flex: auto;
                    margin-bottom: 0.875rem;
                }

                .icon-container {
                    height: 1.25rem;
                    margin: 0 0.5rem 0 1.5rem;
                    width: 1.25rem;
                }
            }

            .dealer-list {
                position: relative;
                z-index: 0;

                &.disabled {
                    display: none;
                }

                .table {
                    tr {
                        border: none;
                        padding: 0;

                        td {
                            width: 100%;
                        }
                    }

                    .dealer {
                        border-bottom: $border-table;
                        cursor: pointer;
                        padding: 1.125rem 1.5rem;

                        &.active {
                            border: 2px solid $button-border-color;
                            border-radius: $button-radius;
                        }

                        .dealer-name-container {
                            display: flex;
                            justify-content: space-between;
                        }

                        .dealer-service-num {
                            display: none;
                        }

                        .dealer-ev-tag {
                            display: flex;
                        }

                        .dealer-availability {
                            display: flex;
                        }
                    }
                }

                .show-more-btn {
                    margin: 2rem auto 0;
                }
            }
        }

        .map-container {
            &.disabled {
                display: none;
            }

            .map {
                height: 24.25rem;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .dl-container .main-row .list-container .dealer-list .table tr {
            margin-right: 0;
        }

        .branch-dealer-url {
            max-width: 12.375rem;
            overflow: hidden;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .table,
        table {
            max-width: 100%;
        }
    }

    @media screen and (min-width: $desktop) {
        .btn-primary.ac-button-primary,
        .primary-button,
        .btn-primary,
        .ac-button-primary {
            width: 90%;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 3.125rem 6.5rem;
        padding-bottom: 6rem;
        .secondary-button {
            margin: 0 auto;
            width: $button-width-desktop;
        }

        .dl-results-label {
            margin-bottom: 1.875rem;
        }

        .main-row {
            flex-direction: row;
            height: 36.5rem;

            .list-container {
                flex: 0 1 40%;
                overflow-y: scroll;

                .btn-group-toggle {
                    margin-right: 1rem;
                    width: 100%;

                    &:empty {
                        display: none;
                    }
                }

                .dealer-list {
                    &.disabled {
                        display: block;
                    }

                    .table {
                        tr {
                            margin-right: 0.25rem;
                        }

                        .dealer {
                            padding: 1.125rem 0.5rem;

                            .dealer-phone-num-link {
                                display: none;
                            }

                            .dealer-service-num {
                                display: block;
                            }
                        }
                    }
                }
            }

            .button-container {
                background-color: $white;
                display: flex;
                position: sticky;
                top: 0;
                z-index: 10;

                .icon-container {
                    display: none;

                    &.list {
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                }
            }

            .map-container {
                flex: 0 1 60%;

                &.disabled {
                    display: block;
                }

                .map {
                    height: 36.5rem;
                }
            }
        }
    }
}
