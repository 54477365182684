.container {
    cursor: pointer;
    display: inline-block;
    padding-left: 1.875rem;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input[type='checkbox'] {
        cursor: pointer;
        opacity: 0;
        position: absolute;

        &:checked {
            ~ .checkmark {
                background-color: $radio-button-background;
                border: $radio-button-border;

                &::after {
                    display: block;
                }
            }
        }
    }

    .checkmark {
        background-color: $white;
        border: $radio-button-border;
        border-radius: 50%;
        cursor: pointer;
        height: 1.25rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 1.25rem;

        &.checked {
            &.disabled {
                border: $radio-button-border;
            }
        }

        &:hover {
            border: $radio-button-border;
        }

        &::after {
            background: $radio-button-dot;
            border-radius: 50%;
            content: '';
            display: none;
            height: 0.875rem;
            left: 3px;
            position: absolute;
            top: 3px;
            width: 0.875rem;
        }

        &.disabled {
            &:hover {
                border: $radio-button-border;
                cursor: default;
            }

            &.checked {
                &::after {
                    background-color: $radio-button-dot;
                    cursor: default;
                }
            }
        }
    }
}
