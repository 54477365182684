@import '../../assets/scss/_variables';

.my-garage-container {
    font-size: inherit;
    justify-content: center;
    padding: 1.875rem 1.5rem;

    .garage-options {
        display: flex;
        flex-direction: column;
    }

    .headline-border {
        margin-bottom: 0.625rem;
    }

    .footer-buttons {
        align-items: center;
        flex-direction: column-reverse;
        text-align: center;

        .button {
            min-width: 18rem;
        }
    }
    @media screen and (min-width: $desktop) {
        .footer-buttons {
            flex-direction: row;
            justify-content: center;

            .button {
                min-width: 20rem;
            }
        }
    }
}
