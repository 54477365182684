@font-face {
    font-family: GMGlobalSans;
    src: url('../fonts/GMGlobalSans.ttf') format('truetype');
}

@font-face {
    font-family: GMGlobalSansBold;
    src: url('../fonts/GMGlobalSansBold.ttf') format('truetype');
}

@font-face {
    font-family: LouisGlobal2Bold;
    src: url('../fonts/LouisGlobal2-Bold.otf') format('opentype');
}

@font-face {
    font-family: LouisGlobal2Reg;
    src: url('../fonts/LouisGlobal2-Reg.otf') format('opentype');
}

@font-face {
    font-family: CadillacSansABook;
    src: url('../fonts/CadillacSansA-Book.ttf') format('truetype');
}

@font-face {
    font-family: StratumGMC;
    src: url('../fonts/StratumGMC-Black.otf') format('opentype');
}

@font-face {
    font-family: BUIFUBK;
    src: url('../fonts/BUIFUBK_.ttf') format('truetype');
}
