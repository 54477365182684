.appointment-concierge {
    &-border,
    &-checkbox-container,
    &-disclaimer,
    &-input {
        margin-bottom: 1.5rem;
    }

    &-input {
        display: flex;
        flex-direction: column;

        &-dropoff,
        &-pickup {
            &.MuiFormControl-root {
                margin-bottom: 1.5rem;
            }
        }
    }

    &-checkbox-container {
        align-items: center;
        display: flex;
        margin-bottom: 0.625rem;

        .checkbox-container {
            margin: 0 0.625rem 0 0;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 0 2.875rem;

        &-input {
            //flex-direction: row;

            &-pickup {
                flex: 0 0 60%;
                margin-bottom: 0;

                &.MuiFormControl-root {
                    margin-right: 1rem;
                }
            }
        }

        &-input-dropoff {
            &.MuiFormControl-root {
                //width: 60%;
            }
        }
    }
}
