@import './Offers';

.more-info-container {
    > div {
        &:not(:last-child) {
            margin-bottom: 0.625rem;
        }
    }

    .desc-title {
        list-style: none;
        margin-left: 1rem;

        &::before {
            content: '- ';
            margin-left: -1rem;
        }
    }

    .item-title {
        align-items: flex-start;
        display: flex;
        font-size: 1.125rem;
        justify-content: space-between;
    }

    .item-price-secondary {
        .display-price-before-decimal {
            font-size: 1.5rem;
        }
    }

    .item-description,
    .item-disclaimer,
    .item-label,
    .item-subtitle {
        @extend %body2;

        color: $text-grey-65;
    }

    .item-disclaimer {
        font-style: italic;
    }

    .item-label,
    .item-subtitle {
        font-family: $font-family-bold;
    }

    .service-item-details-link {
        margin-top: 0.5rem;
    }
}
