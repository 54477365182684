.need-something-else-container {
    margin-top: 3.125rem;

    .need-something-else-toggle {
        width: max-content;
    }

    .services-title {
        .headline-border {
            margin-bottom: 1.875rem;
        }
    }

    .search-input {
        margin-bottom: 1rem;
    }

    .MuiFormControl-root {
        .MuiFormLabel-root {
            @extend %body1;

            color: $text-grey-65;
        }
    }

    .submit-appointment-comments {
        .submit-appointment-comments-box,
        .submit-appointment-length-remaining {
            margin-bottom: 1.125rem;
        }

        .submit-appointment-comments-box {
            height: 4.125rem;
        }
    }
}
