body {
    .MuiTypography-body1 {
        @extend %body1;
    }

    .PrivateSwitchBase-root-2 {
        margin-right: 0.875rem;
        padding: 0;
    }

    .MuiIconButton-root {
        padding: 0;

        .location-airplane {
            height: 20px;
            width: 20px;
        }
    }

    MuiFormControlLabel-label {
        margin-top: 0.25rem;
    }

    .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 3.125rem;
    }

    // Error messages
    .MuiFormHelperText-root {
        @extend %body1;

        display: none;
        font-size: 0.75rem;
        line-height: 1.33;

        &.Mui-error {
            color: $error-red;
            display: block;
            margin-left: 0.875rem;
        }
    }

    .MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            @extend %body1;

            color: $light-grey-ac;
            margin-left: 0.25rem;
            z-index: 1;

            &:not(.Mui-disabled) {
                color: $text-grey-65;

                &.Mui-error {
                    color: $error-red;
                }

                &.MuiInputLabel-shrink {
                    @extend %body2, %caps;

                    color: $text-grey-65;

                    ~ .MuiFilledInput-root {
                        .MuiInputBase-input {
                            padding: 1.875rem 1rem 0.5rem;
                        }
                    }
                }
            }
        }

        .MuiFilledInput-root {
            @extend %body1;

            border-top-left-radius: 0.313rem;
            border-top-right-radius: 0.313rem;

            &:not(.Mui-disabled) {
                background-color: $form-fill;

                &::before {
                    border-bottom: 1px solid $btn-disabled-grey;
                }

                &:hover {
                    &::before {
                        border-bottom: 1px solid $cadillac-dark-grey;
                    }
                }

                &.Mui-error {
                    &::before,
                    &::after {
                        border-color: $error-red;
                    }

                    &:hover {
                        &::before {
                            border-color: $error-red;
                        }
                    }
                }

                .MuiSelect-icon {
                    opacity: 1;
                }
            }

            .MuiSelect-icon {
                @include insert-icon(arrow-right, $svg-small, $svg-small);

                opacity: 0.3;
                right: 1.25rem;
                top: calc(50% - 0.25rem);
                transform: rotate(90deg);

                path {
                    display: none;
                }
            }

            .MuiInputAdornment-filled {
                margin-top: 0;
            }

            .MuiFilledInput-input {
                &:not(.MuiSelect-select) {
                    padding: 1.25rem 1rem 1.25rem 0;
                }

                &.MuiSelect-select {
                    padding-left: 1rem;

                    &:focus {
                        background-color: inherit;
                        border-top-left-radius: 0.313rem;
                        border-top-right-radius: 0.313rem;
                    }
                }

                &.Mui-disabled {
                    color: $light-grey-ac;
                }
            }
        }
    }

    .MuiPopover-paper {
        border-radius: 0.313rem;
        box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.2);
        max-width: 100%;

        .menu-group-name {
            font-family: $font-family-bold;
            margin-left: 1rem;
        }

        .MuiMenuItem-root {
            @extend %body1;

            padding-left: 1.75rem;
        }
    }

    // Spinner
    .MuiCircularProgress-root {
        display: block;
        margin: 0 auto;

        &.MuiCircularProgress-colorPrimary {
            color: $oss-primary-color;
        }
    }

    // Tooltip
    .MuiTooltip-tooltip {
        @extend %body1;

        background-color: $oss-primary-color;
        color: $oss-invert-color;
        padding: 1rem;
    }

    // Alert
    .MuiAlert-root {
        @extend %body1;

        background-color: transparent;
        border: $border-card;
        border-radius: 0;
        font-family: $font-family-bold;

        .MuiAlert-icon {
            padding-top: 0.625rem;
        }
    }
}
