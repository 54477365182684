.appointment-details-container {
    margin-bottom: 3.125rem;

    .appointment-details-dropdowns {
        display: flex;
        flex-direction: column;

        .appointment-details-advisor,
        .appointment-details-transport {
            margin-bottom: 0.625rem;

            &:empty {
                display: none;
            }

            .advisor-dropdown-label,
            .transport-dropdown-label {
                margin: 0.5rem 0 0 1rem;
            }
        }

        .appointment-details-advisor {
            margin-bottom: 0.625rem;
        }
    }

    .transport-info {
        display: flex;
        padding: 1rem;

        &-icon {
            margin-right: 1rem;
            min-width: 1.875rem;
        }

        &-dropoff-address,
        &-headline,
        &-pickup-address {
            margin-bottom: 0.625rem;
        }

        &-disclaimer {
            font-size: 0.875rem;
        }

        &-pickup-title,
        &-dropoff-title {
            @extend %bold;
        }

        &-pickup-address,
        &-dropoff-address {
            margin-bottom: 0.625rem;
        }
    }

    .transport-info-error {
        border:1px solid $error-red;
    }

    @media screen and (min-width: $desktop) {
        .appointment-details-dropdowns {
            flex-direction: row;
            margin-bottom: 0.5rem;

            .appointment-details-advisor,
            .appointment-details-transport {
                margin-bottom: 0;
                width: 50%;
            }

            .appointment-details-advisor {
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }
    }
}
