@import '../../assets/scss/_variables';

.garage-container {
    justify-content: center;
    padding-bottom: 8rem;
    padding-top: 1rem;
    text-align: center;

    .car-container {
        flex-direction: column;
        min-width: 20rem;

        .card {
            display: flex;
            flex-direction: row;

            .car-radio {
                margin-top: 0.1rem;
                padding: 0.5rem;
                text-align: end;
                vertical-align: top;

                .Mui-checked {
                    color: $cadillac-dark-grey;
                }
            }

            .car-image {
                height: 3.375rem;
                margin: auto;
                width: 6rem;
            }

            .no-car-image {
                display: flex;
                height: 2rem;
                margin: 1.5rem auto 1rem;
                width: 2.5rem;
            }

            .car-line {
                background-color: $fill-line-grey;
                display: none;
                height: 1px;
                margin: 0.625rem 1.375rem 0.375rem;
            }

            .vehicle-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 4rem;
                padding: 1rem;

                .car-selected {
                    display: flex;
                    justify-content: center;
                }

                .car-ymm {
                    font-weight: bold;
                }
            }

            &.MuiPaper-outlined {
                border-color: $cadillac-dark-grey;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .car-container {
            flex-direction: row;

            .card {
                flex-direction: column;
                min-height: 20rem;

                .car-image {
                    height: 9rem;
                    width: 16rem;
                }

                .no-car-image {
                    height: 4rem;
                    margin-bottom: 2.5rem;
                    margin-top: 4rem;
                    width: 4.5rem;
                }

                .car-line {
                    display: flex;
                }
            }
        }
    }
}
