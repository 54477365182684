.vehicle-container {
    padding: 1.875rem 1.5rem 5rem;

    .vehicle-headline {
        margin: 0 auto 2rem;
        max-width: 20.25rem;
    }

    .garage-error {
        margin-bottom: 1rem;
    }

    .Enter-your-license-p,
    .vehicle-label {
        margin: 0 auto;
        width: 20.25rem;
    }

    .vehicle-fields {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem 0;

        .vehicle-fields-grid-container {
            display: grid;
            grid-template-columns: minmax(min-content, 20.25rem);
            justify-content: center;
            row-gap: 1.25rem;

            .vehicle-dropdown {
                .MuiFormLabel-root {
                    margin-left: 1.125rem;
                    top: -0.25rem;

                    &.MuiInputLabel-shrink {
                        top: 0.625rem;
                    }
                }
            }

            .vehicle-registration-tooltip-label {
                align-items: center;
                display: flex;
                margin-top: 1rem;

                .info {
                    height: 1.125rem;
                    margin-right: 0.5rem;
                    width: 1.125rem;
                }
            }

            .vehicle {
                border-bottom: $border-table;
                cursor: pointer;
                padding: 0.625rem 1.25rem;

                &.selected {
                    border: 2px solid $button-border-color;
                    border-radius: $button-radius;
                }

                .myRow {
                    &.withImage {
                        display: flex;

                        .vehicle-details {
                            text-align: left;
                        }
                    }

                    .Bitmap {
                        margin-right: 1rem;
                        width: 9.375rem;
                    }

                    .vehicle-details {
                        font-size: 0.875rem;
                        line-height: 1.5;
                        margin-top: 0.375rem;
                        text-align: center;

                        .vehicle-vin {
                            color: $text-grey-65;
                        }
                    }
                }
            }
        }
    }

    .vehicle-label {
        .mileage-value {
            margin-left: 0.3em;
        }
    }

    .privacy-container {
        margin-top: 2.5rem;
        max-width: 20.25rem;
    }

    @media screen and (min-width: $desktop) {
        padding: 3.125rem 6.5rem;

        .vehicle-headline {
            max-width: 47rem;
        }

        .Enter-your-license-p,
        .vehicle-label {
            width: 23rem;
        }

        .vehicle-fields {
            .vehicle-fields-grid-container {
                grid-template-columns: minmax(min-content, 23rem);

                &.two-columns {
                    column-gap: 1rem;
                    grid-auto-flow: column;
                    grid-template-columns: repeat(2, minmax(min-content, 23rem));
                    grid-template-rows: repeat(3, min-content);
                }
            }

            .privacy-container {
                align-items: center;
                max-width: 23rem;

                &.two-columns {
                    max-width: 47rem;
                }
            }
        }
    }
}

// Dropdown styling
body {
    .MuiPopover-paper {
        .MuiMenuItem-root {
            &.menu-group-name {
                @extend %bold;

                font-size: 1.25rem;
                opacity: 1;
                padding: 0.375rem 0.5rem;

                ~ .MuiListItem-gutters {
                    &:not(.menu-group-name) {
                        padding: 0.375rem 1rem;
                    }
                }
            }
        }
    }
}

// Tooltip Styling
.vehicle-registration-tooltip {
    background-color: $cadillac-dark-grey;
    border-radius: 0.625rem;
    color: $white;
    max-width: 21rem;
    padding: 1rem;
    z-index: 1000;
}
