@import './Stepper';

.header {
    border-bottom: $border-brand-thick_neutral;
    padding: 1.125rem 1.5rem;

    .header-navigation {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: $desktop) {
        padding: 1.75rem 1rem 1.75rem 1.75rem;
    }
}
