.appointment-days-container {
    margin-bottom: 1.875rem;

    .appointment-days-selector {
        align-items: center;
        display: flex;

        .prev-btn,
        .next-btn {
            margin-top: 2.5rem;
        }

        .prev-btn {
            margin-right: 0.5rem;
        }

        .next-btn {
            margin-left: 0.5rem;
        }

        .appointment-list {
            display: flex;
            justify-content: space-between;
            touch-action: none;
            width: 100%;

            .appointment-day {
                flex: 0 1 3.75rem;
                font-size: 1.125rem;
                text-align: center;

                .appointment-day-of-week {
                    margin-bottom: 1rem;
                }

                .appointment-day-of-month {
                    align-items: center;
                    border-radius: 50%;
                    cursor: pointer;
                    display: flex;
                    font-family: $font-family-primary;
                    font-size: 1.125rem;
                    height: 3.625rem;
                    justify-content: center;
                    -khtml-user-select: none;
                    -ms-user-select: none;
                    -webkit-user-select: none;
                    user-select: none;
                    width: calc(100% - 2px);
                }
            }
        }
    }

    @media screen and (min-width: $desktop) {
        margin-bottom: 2.5rem;
        padding: 0 1rem;

        .appointment-days-selector {
            .appointment-list {
                margin: 0 3rem;

                .appointment-day {
                    .appointment-day-of-week {
                        margin-bottom: 0.875rem;
                    }
                }
            }
        }
    }
}
