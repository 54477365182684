.appointment-month-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .appointment-month {
        font-size: 1.25rem;
        line-height: 1.33;
        margin: 0 0.5rem;
    }
}
