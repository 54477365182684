.pega-loading-container {
    padding: 12rem 1.5rem 3.125rem 1.5rem;
    text-align: center;

    .pega-loading-text {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: $desktop) {
        padding: 12rem 6.375rem 3.125rem 6.375rem;
    }
}

.dev-entry {
    display: grid;
    margin: 0 auto 1rem;
    max-width: 25rem;
    padding: 0 2rem 2rem;
    row-gap: 1rem;

    label {
        display: flex;
        justify-content: space-between;
    }
}
