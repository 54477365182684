.loading {
    margin: 20% auto 0;
}

.landing-container {
    padding: 1.875rem 1.5rem 1.5rem;

    .landing-body {
        justify-content: center;
        margin: 1rem 0 1.5rem;

        .landing-prompt {
            margin-bottom: 1.5rem;
        }

        .button {
            margin: 0 auto 1rem;
        }
    }

    @media screen and (min-width: $desktop) {
        .landing-body {
            margin-top: 2rem;

            .landing-prompt {
                font-family: $font-family-bold;
                font-size: $headline3-font-size-desktop;
                line-height: $headline3-line-height-desktop;
                text-align: center;
            }
        }

        .landing-subtitle,
        .helpful-links {
            text-align: center;
        }
    }
}
