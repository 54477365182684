@use "../../assets/scss/variables";

.footer {
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
    position: fixed;
    margin-bottom: 10px;

    .button {

        margin: 1.125rem;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 1.125rem;
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: variables.$desktop) {
        flex-direction: row;
        //justify-content: center;

        .button {
            flex: 0 1 variables.$button-width-desktop;

            &.cancel-button {
                margin: 0 auto;
                order: 0;
            }

            &.main-button {
                margin-bottom: 1rem;
                order: 1;
            }
        }
    }
}
